import { useConfig } from '@bluheadless/ui-logic/src/providers/config'
import ImageProvider from '@bluheadless/ui-logic/src/providers/image'
import { cx } from '@emotion/css'
import Grid from '@mui/material/Grid'
import { ArtDirectionWrapper, default as ImageArtDirection, VideoArtDirection } from '../../particles/art-direction'
import { IMAGE_COMPONENT, IMAGE_BACKGROUND_COMPONENT } from '@bluheadless/ui-logic/src/constants'
import {
	Container,
	CtaContainer,
	Description,
	Description2,
	ImageColumn,
	Subtitle,
	TextColumn,
	Title,
	Column,
	ReadMoreWrapper,
	ReadMoreCta,
	Link,
	Button,
} from './editorial.styled'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useInView } from 'react-intersection-observer'

const Editorial = ({
	aboveTheFold,
	className,
	imageLink,
	imageLinkTarget,
	imageSrc,
	imageOriginalWidth,
	imageOriginalHeight,
	imageProps,
	imageSources,
	reverse,
	title,
	titleProps,
	subtitle,
	subtitleProps,
	description,
	description2,
	descriptionProps,
	cta,
	ctaProps,
	video,
	videoConfig,
	...props
}) => {
	const noFadeInCssClass = className.indexOf('no-fade-in') > -1

	const [containerRef, inView] = useInView({
		threshold: 0.5,
		triggerOnce: true,
	})

	const { siteName } = useConfig()

	const [showMore, setShowMore] = useState(false)
	const showIn = noFadeInCssClass || inView

	const { formatMessage } = useIntl()

	return (
		<Container
			ref={containerRef}
			aboveTheFold={aboveTheFold}
			reverse={reverse}
			{...props}
			className={cx('Editorial-root', className)}
		>
			<ArtDirectionWrapper responsiveProps={{ xs: {}, sm: { sizes: 50 } }} ChildComponent={ImageProvider}>
				<ImageColumn>
					{video?.mobile?.url || video?.desktop?.url ? (
						<VideoArtDirection
							sources={{
								xs: video.mobile,
								md: video.desktop,
							}}
							{...videoConfig}
						/>
					) : (
						<Link href={imageLink} target={imageLinkTarget} rel="nofollow">
							<ImageArtDirection
								aboveTheFold={aboveTheFold}
								type={aboveTheFold ? IMAGE_BACKGROUND_COMPONENT : IMAGE_COMPONENT}
								src={imageSrc}
								alt={title + ' - ' + siteName}
								originalWidth={imageOriginalWidth}
								originalHeight={imageOriginalHeight}
								sources={imageSources}
								{...imageProps}
							/>
						</Link>
					)}
				</ImageColumn>
				<TextColumn>
					{subtitle && (
						<Subtitle show={showIn} variant="subheadline1" tabIndex="0" {...subtitleProps}>
							{subtitle}
						</Subtitle>
					)}
					{title && (
						<Title show={showIn} variant="headlinebig" tabIndex="0" {...titleProps}>
							{title}
						</Title>
					)}
					{(description || description2) && (
						<Column>
							<Description show={showIn} variant="subheadline1" tabIndex="0" {...descriptionProps}>
								{description}
							</Description>

							{description2 && (
								<ReadMoreWrapper>
									<Description2 show={showMore}>{description2}</Description2>
									<ReadMoreCta tabIndex={0} onClick={() => setShowMore(!showMore)} className={showMore ? 'active' : ''}>
										{formatMessage({ id: !showMore ? 'cta_read_more' : 'cta_read_minus' })}
									</ReadMoreCta>
								</ReadMoreWrapper>
							)}
						</Column>
					)}

					{cta && cta?.length > 0 && (
						<CtaContainer show={showIn} display="inline-flex" justifyContent={props.horizontalAlign} {...ctaProps}>
							{cta.map(
								({ label, url, variant, ...rest }, key) =>
									label && (
										<Grid item key={key}>
											<Button tabIndex={0} label={label} href={url} variant={variant} {...rest}>
												{label}
											</Button>
										</Grid>
									)
							)}
						</CtaContainer>
					)}
				</TextColumn>
			</ArtDirectionWrapper>
		</Container>
	)
}

Editorial.defaultProps = {
	reverse: false,
	imageSources: { xs: {} },
}

export default Editorial
